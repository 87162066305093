<template>
  <section class="min-w1600">
    <div class="searchwrap">
      <div class="searchBar">
         <div class="box-ui-check searchbar-content">
           <div class="title"><span>{{ $t('searchArea.date') }}</span></div>
           <div class="fx">
             <date-selector :class="'searchbar-content'" @setStartDate="setStartDate" @setEndDate="setEndDate" :startDefault="startDefault" :endDefault="endDefault" :configs="dateConfigs" />
           </div>
         </div>
      </div>
      <div class="searchBar">
         <div class="box-ui-check searchbar-content">
            <div class="title"><span>{{ $t('common.member') }}</span></div>
            <div>
               <input type="text" v-model="reqData.memId" :placeholder="$t('common.id')" />
            </div>
         </div>
         <div class="box-ui-check searchbar-content">
            <div class="title"><span>{{ $t('common.admin') }}</span></div>
            <div>
               <input type="text" class="ml-5" v-model="reqData.adminId" :placeholder="$t('common.admin')" />
               <!-- <input type="text" class="ml10" v-model="reqData.searchValue" placeholder="문의내용" /> -->
               <button class="btn-search ml-5" type="button" @click="pageSeach">
                  <i class="fas fa-search"></i>
               </button>
               <template v-if="boardType === 'reply'">
                 <button type="button" class="btn-layout btn-grey ml-5" @click="addReply">{{$t('button.aAdd')}}</button>
               </template>
            </div>
         </div>
      </div>
      <memo></memo>
    </div>
    <div class="main-contents-wrapper">
      <article class="total-info-container fx-col-sbw">
        <div class="total-info-wrap">
          <div class="total-info-item">
            <img :src="require('@/assets/img/searchicon.png')" />
            <i class="fas fa-search"></i>
            <span>{{ $t('searchArea.schResult') }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_list_count`) }}</span> : <span class="value">{{ pageInfo.tatal_list_count }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.page`) }}</span> : <span class="value">{{ pageInfo.page }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_page_count`) }}</span> : <span class="value">{{ pageInfo.tatal_page_count }}</span>
          </div>
        </div>
      </article>
      <div class="tab-wrap">
        <button type="button" class="tab-item" :class="{ active: boardType === 'qna' }" @click="changeType('qna')">{{ $t('common.qna2') }}</button>
        <button type="button" class="tab-item" :class="{ active: boardType === 'reply' }" @click="changeType('reply')">{{ $t('button.answerList') }}</button>
      </div>
      <div class="table-wrapper">
        <table class="mainTable fixed">
          <table-head :headInfo="headInfo[boardType]" />
          <tbody v-if="tableData.length > 0">
            <template v-if="boardType === 'qna'" v-for="(item, idx) in tableData" :key="item.boardIdx">
              <tr>
                <td class="roboto">{{ Number(pageInfo.tatal_list_count) - idx }}</td>
                <!-- <td><input type="checkbox"/></td> -->
                <td><button type="button" class="fc-id btn-link" @click="detailOpen('user', item.regId)">{{ item.regId }}</button></td>
                <td @click="openMemberPopup(item, 'isOpenMemo')">{{ item.memNick }}</td>
                <td>
                  <!-- class="partWrap"
                  <span class="" @click="item.isOpenTopUser = true">{{ item.topUserList[0] }}</span>
                  <div class="partBoxWrap" v-if="item.isOpenTopUser">
                    <div class="partBox">
                      <template v-for="(partner, index) in item.topUserList">
                        {{partner}}
                        <template v-if="item.topUserList[index+1]">
                          ◀
                        </template>
                      </template>
                    </div>
                    <a @click="item.isOpenTopUser = false"><i class="fa fa-times"></i></a>
                  </div-->
                  <div class="topwrap" v-if="item.topUserList">
                    <select class="select">
                       <template v-for="(partner, index) in item.topUserList">
                          <option class="option">
                             <span class="topbox">[{{computedPartnerLevel(partner)}}] </span>
                             <span :class="item.partnerLevel">{{partner.recommenderId}}</span>
                          </option>
                       </template>
                    </select>
                    <i class="icon">+</i>
                 </div>
                </td>
                <td>{{ $t(item.faqType) }}</td>
                <!--td><button type="button" class="fc-id btn-link" @click="detailOpen('user', item.recommenderId)">{{ item.recommenderId }}</button></td-->
                <td>
                  <div class="txt">{{ item.title }}</div>
                </td>
                <td><div class="txt roboto">{{ item.regDate }}</div></td>
                <td>
                  <div class="txt roboto">{{ item.status === "ANSWER" ? item.updDate : "-" }}</div>
                </td>
                <td>
                  <span>{{ !item.adminId ? "-" : item.adminId }}</span>
                </td>
                <td>
                   <span class="fc-red" v-if="item.status === 'WAIT'" @click="item">{{ $t('common.wait') }}</span>
                   <span class="fc-blue" v-if="item.status === 'ANSWER'">{{ $t('common.comAns') }}</span>
                </td>
                <td>
                  <div class="status-change-btn-wrap">
                    <button class="btn-innerTable btn-status-change approve" type="button" v-if="item.status === 'WAIT'" @click="getContentDetail(item.boardIdx)">답글달기</button>
                    <button class="btn-innerTable btn-status-change wait" type="button" v-if="item.status === 'ANSWER'" @click="getContentDetail(item.boardIdx)">답글수정</button>
                    <button class="btn-innerTable btn-status-change wait" type="button" @click="deleteContent(item.boardIdx)">{{ $t('button.del') }}</button>
                  </div>
                </td>
              </tr>
            </template>
            <template v-if="boardType === 'reply'" v-for="(item, idx) in tableData" :key="item.idx">
            <tr>
              <td class="roboto">{{ item.idx }}</td>
              <!-- <td><input type="checkbox"/></td> -->
              <td>{{ item.title }}</td>
              <td><div class="txt">{{ item.answer }}</div></td>
              <td>{{ item.answerType }}</td>
              <td>{{ item.useYn }}</td>
              <td class="roboto">{{ item.regDate }}</td>

              <td>
                <div class="status-change-btn-wrap">
                  <button class="btn-innerTable btn-status-change wait" type="button" @click="getReplyDetail(item)">{{ $t('button.modify') }}</button>
                  <button class="btn-innerTable btn-status-change wait" type="button" @click="deleteReply(item.idx)">{{ $t('button.del') }}</button>
                </div>
              </td>
            </tr>
          </template>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="12">
                <span class="notice-tableDataEmpty">{{ $t('txt.noData') }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="tableData.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />

    <div class="modal-wrapper" v-if="updModalActive">
      <div class="modal-wrap">
        <article>
          <div class="sub-title">
            <h3>{{ $t('common.detail') }}</h3>
          </div>
          <div class="page-content">
             <div class="item-row-container">
                <div class="item-wrap">
                   <div class="item-title" style="flex: 0 0 10%">{{ $t('table.head.qTitle2') }}</div>
                   <div class="item-content">
                      <input class="input mw100" type="text" v-model="boardDetail.title" :readonly="boardType != 'reply'" />
                   </div>
                </div>
             </div>

             <div class="item-row-container" v-if="boardType != 'reply'">
                <div class="item-wrap">
                   <div class="item-title" style="flex: 0 0 10%">{{ $t('table.head.content') }}</div>
                   <div class="item-content">
                      <textarea v-model="boardDetail.content" readonly></textarea>
                   </div>
                </div>
             </div>

             <div class="item-row-container" v-if="boardType != 'reply'">
                <div class="item-wrap">
                   <div class="item-title" style="flex: 0 0 10%">{{ $t('common.ansList') }}</div>
                   <div class="item-content">
                      <select class="mw100" @change="onChangeRegReply" v-model="selectRegReply">
                         <option value="" selected>{{ $t('txt.choose') }}</option>
                         <template v-for="(item, index) in regReplyList">
                           <option :value="item">{{ item.title }}</option>
                         </template>
                      </select>
                   </div>
                </div>
             </div>

             <div class="item-row-container">
                <div class="item-wrap">
                   <div class="item-title" style="flex: 0 0 10%">{{ $t('table.head.answer') }}</div>
                   <div class="item-content">
                      <textarea class="answerBox" v-model="repleModel.content"></textarea>
                   </div>
                </div>
             </div>

             <div class="modal-btns">
               <a @click="saveComment(boardDetail)" v-if="boardType != 'reply'">{{ $t('button.save') }}</a>
               <a @click="currentSave">{{ $t('button.answerSave') }}</a>
               <a @click="modalClose()">{{ $t('button.close') }}</a>
             </div>
          </div>
        </article>
      </div>
    </div>

    <div class="modal-wrapper" v-if="isOpenMemo">
      <div class="modal-wrap">
        <article>
          <div class="sub-title">
            <h3>{{ $t('table.head.takeNote') }}</h3>
          </div>
          <div class="page-content">
             <div class="item-row-container">
                <div class="item-wrap">
                   <!--div class="item-title"></div-->
                   <div class="item-content">
                      <textarea :placeholder="$t('txt.enterNote')" v-model="selectMember.memo"> {{selectMember}}</textarea>
                   </div>
                </div>
             </div>
             <div class="modal-btns">
              <a @click="setMemo(selectMember)">{{ $t('button.save') }}</a>
              <a @click="isOpenMemo = false;selectMember = null">{{ $t('button.close') }}</a>
             </div>
          </div>
        </article>
      </div>
    </div>

  </section>
</template>

<script>
import lodash from 'lodash'
import TableHead from '@/components/main/table/Head.vue'
import DateSelector from '@/components/common/DateSelector'
import Pagination from '@/components/common/Pagination'
import { getSiteData } from '@/libs/auth-helper'
import { boardList, boardDetail, boardSave, cmtSave, currentSave, replyList, replyDelete } from '@/api/board.js'
import { replaceDateT, numberWithCommas, getDateStr } from '@/libs/utils.js'
import { PARTNER_LEVEL } from '@/libs/constants'
import { setMemberMemo } from '@/api/member.js'
import Memo from '@/components/common/memo'
export default {
  name: 'BoardQna',
  components: {
    TableHead,
    Pagination,
    DateSelector,
    Memo
  },
  data () {
    return {
      part: false,
      tableName: this.$route.name,
      headInfo: {
        qna: {
          fstColumn: false,
          dataList: {
            index: 3,
            id: 8,
            nick: 8,
            upper: 10,
            category: 6,
            qContent: 25,
            writedate: 9,
            adate: 9,
            adminw: 10,
            status: 5,
            option: 6
          }
        },
        reply: {
          fstColumn: false,
          dataList: {
            idx: 3,
            title: 10,
            answer: 30,
            answerType: 10,
            useYn: 6,
            regDate: 30,
            option: 8
          }
        }

      },
      reqData: {
        boardType: 'faq',
        category: '',
        count_per_list: 30,
        endDate: '',
        page: 1,
        startDate: ''
      },
      repleModel: {
        content: ''
      },
      dateConfigs: {
        enableTime: false,
        dateFormat: 'Y-m-d'
      },
      startDefault: '',
      endDefault: '',
      tableData: [],
      pageInfo: {
        tatal_list_count: 0,
        tatal_page_count: 0,
        page: 0
      },
      updModalActive: false,
      boardType: 'qna',
      boardDetail: {},
      regReplyList: [],
      selectRegReply: '',
      isOpenMemo: false,
      selectMember: null
    }
  },
  methods: {
    openMemberPopup (item, type) {
      this[type] = !this[type]
      this.selectMember = item
    },
    async setMemo (item) {
      if (!item.memo) {
        alert('내용을 입력해주세요.')
      } else {
        const req = {
          memId: item.memId,
          memo: item.memo
        }
        const res = await setMemberMemo(req)
        if (res.resultCode === '0') {
          alert('메모 입력 완료')
        } else {
          alert('메모입력 실패, 다시 시도해주세요.')
        }
        this.isOpenMemo = false
      }
    },
    computedPartnerLevel (item) {
      return PARTNER_LEVEL[item.partnerLevel]
    },
    onChangeRegReply () {
      console.log(this.selectRegReply)

      const reply = { ...this.selectRegReply }
      this.repleModel.content = reply.answer
    },
    deleteReply (idx) {
      const param = {
        idx
      }

      replyDelete(param).then(res => {
        if (res.resultCode === '0') {
          alert('삭제 완료')

          this.setTableData(1)
        }
      })
    },
    async currentSave () {
      const title = this.boardDetail.title
      const answer = this.repleModel.content

      if (!title || !answer) {
        alert('제목 또는 답변을 입력해주세요.')
        return false
      }

      const param = {
        title: this.boardDetail.title,
        answerType: 'board',
        answer: this.repleModel.content
      }

      if (this.boardDetail.idx) {
        param.idx = this.boardDetail.idx
      }

      currentSave(param).then(res => {
        if (res.resultCode === '0') {
          alert('현답변저장 완료')
          if (this.boardType === 'reply') {
            this.updModalActive = false
            this.setTableData(1)
          }
        }
      })
    },
    changeType (type) {
      this.boardType = type
      this.setTableData(1)
    },
    setStartDate (date) {
      // console.log(date);
      let _date = ''
      if (!date) {
        _date = new Date()
      } else {
        _date = date[0]
      }
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd')
    },
    setEndDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
      } else {
        _date = date[0]
      }
      this.endDefault = _date
      this.reqData.endDate = getDateStr(_date, 'yyyy-MM-dd')
    },
    goSearch (page) {
      this.setTableData(page)
    },
    pageSeach () {
      this.setTableData()
    },
    async deleteContent (boardIdx) {
      const trigger = confirm('해당 게시물을 삭제하시겠습니까?')
      if (!trigger) {
        return
      }
      const req = {
        boardIdx,
        delYn: 'Y'
      }
      const boardType = this.boardType
      const res = await boardSave(boardType, req)
      console.log(res)
      if (res.resultCode === '0') {
        alert('게시물 삭제 완료')
        this.setTableData()
      } else {
        alert('게시물 삭제 실패, 다시 시도해주세요.')
      }
    },
    getSiteInfo () {
      const siteInfoCookie = getSiteData()
      if (siteInfoCookie) {
        const siteInfo = JSON.parse(siteInfoCookie)
        return siteInfo
      }
    },
    modalClose () {
      this.updModalActive = false
      this.boardDetail = {}
      this.repleModel = {}
      this.selectRegReply = ''
    },
    detailModalOpen (type) {
      this.loadReplyList()
      if (type === 'c') {
        this.boardDetail = {}
      }
      this.updModalActive = true
    },
    async getContentDetail (boardIdx) {
      const req = {
        boardIdx
      }
      const res = await boardDetail(this.boardType, req)
      this.boardDetail = res.data.board
      console.log(this.boardDetail)
      if (this.boardDetail.comment.length !== 0) {
        this.repleModel.content = this.boardDetail.comment[0].content
      }
      this.detailModalOpen()
    },
    async getReplyDetail (item) {
      this.boardDetail = { ...item }
      this.repleModel = {
        content: item.answer
      }
      this.detailModalOpen()
    },
    addReply () {
      this.updModalActive = true
    },
    async saveComment (item) {
      const reqData = {
        content: this.repleModel.content
      }
      const addModel = {}
      if (item.status === 'ANSWER') {
        addModel.cmtIdx = item.comment[0].cmtIdx
      } else if (item.status === 'WAIT') {
        addModel.boardIdx = item.boardIdx
        addModel.regId = !this.siteInfo.adminId ? this.siteInfo.siteId : this.siteInfo.adminId
        addModel.cmtType = 'B'
      }
      Object.assign(reqData, addModel)
      const res = await cmtSave(reqData)
      if (res.resultCode === '0') {
        alert('답변완료')
        this.modalClose()
        await this.setTableData()
      } else {
        alert('답변 실패. 다시 시도해주세요.')
      }
    },
    loadReplyList (type) {
      const param = {
        answerType: 'board',
        useYn: 'Y'
      }
      replyList(param).then(res => {
        if (res.resultCode === '0') {
          if (type === 'list') {
            this.tableData = res.data.list
          } else {
            this.regReplyList = res.data.list
          }
        }
      })
    },
    async setTableData (page) {
      if (page) {
        this.reqData.page = page
      } else {
        this.reqData.page = 1
      }

      if (this.boardType === 'qna') {
        const req = { ...this.reqData }
        const boardType = this.boardType
        const res = await boardList(boardType, req)
        console.log('list : ', res)
        if (res.data.pageInfo) {
          this.pageInfo = res.data.pageInfo
          this.pageInfo.tatal_list_count = numberWithCommas(this.pageInfo.tatal_list_count)
          this.pageInfo.tatal_page_count = numberWithCommas(Number(this.pageInfo.tatal_page_count) + 1)
        }
        this.tableData = res.data.list
        this.tableData.forEach(item => {
          item.topUserList = item.topUserList.reverse()
        })
      } else {
        this.loadReplyList('list')
      }
    }
  },
  async created () {
    this.siteInfo = this.getSiteInfo()
    this.setStartDate()
    this.setEndDate()
    await this.setTableData(1)
  }
}
</script>

<style scoped>
.mw100 {max-width: 100% !important;width: 100% !important;}
.modal-wrap input[type=text] {width: 190px;}
.sub-title {
   color: #646464;
   font-size: 13px;
   padding-bottom: 11px;
   border-bottom: 2px solid #959595;
   display: flex;
   align-items: center;
   justify-content: space-between;
}
textarea {
  width: 100%;
  min-height: 134px;
  border: 1px solid #aaa;
  resize: none;
  box-sizing: border-box;
  border-radius: 2px;
  background-color: #fafafa;
  padding: 10px;
}
.pt50 {
  padding-top: 50px;
}
.ml10 {
  margin-left: 10px;
}

.contentLimit {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}

textarea.answerBox {
  min-height: 160px;
}

.item-wrap > div.item-title {
  min-width: 70px;
}

.table-wrapper,.mainTable,.mainTable td {overflow: inherit;}
.mainTable td {position: relative;}
.mainTable >>> th:nth-child(3) {/*background: url(~@/assets/img/icon_memos.svg) 75% center no-repeat;*/}
.partWrap {position: relative;overflow: inherit;}
.partWrap > span {cursor: pointer;color: #48004e;line-height: 1.5em;}
.partBoxWrap {position: absolute;top: 41px;left: 25px;color: #fff;z-index: 1;display: flex;align-items: center;gap: 10px;background: #48004e;padding: 5px 10px;border-radius: 5px;}
.partBoxWrap > a {display: flex;align-items: center;justify-content: center;color: #fff;cursor: pointer;}

/* modal */
.modal-wrapper {display: flex;font-size: 16px;}
/*.modal-wrap {padding: 0;width: 550px;min-width: 550px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);margin: 0;}
.page-contents-wrap {padding: 0;background: #353535;}
.sub-title {color: #fff;font-size: 14px;height: 42px;display: flex;align-items: center;justify-content: center;position: relative;border-bottom: 2px solid transparent;}
.sub-title .btn {position: absolute;right: 15px;background: no-repeat;font-size: 22px;width: auto;height: auto;cursor: pointer;}
.page-content {padding: 27px 0 25px;color: #fff;}
.page-content .con-wrap {display: flex;flex-direction: column;gap: 10px;}
.page-content .c-title {font-size: 14px;}
.page-content .content {display: flex;flex-direction: column;gap: 20px;}
.page-content .content .btnWrap {display: flex;align-items: center;justify-content: center;gap: 20px;margin-top: 20px;}
.page-content .content .btnWrap a {width: 168px;height: 35px;display: flex;align-items: center;justify-content: center;font-size: 18px;color: #fff;cursor: pointer;}
.page-content .content .btnWrap a.btn {border: 1px solid #a4a4a4;box-sizing: border-box;background: #545454;}
.page-content .content .input,
.page-content .content select {border: 1px solid #353535;font-size: 14px;width: 356px;height: 30px;}
.page-content .content .input::placeholder,
.page-content .content textarea::placeholder {color: #a4a4a4;}
.page-content .content textarea {resize: none;height: 112px;padding: 10px 12px;font-size: 14px;}

.modal-wrap .sub-title {border-color: #0f84ee;}
.modal-wrap .sub-title .btn {color: #0f84ee;}
.modal-wrap .btnWrap a {background: #0f84ee;}*/
</style>
